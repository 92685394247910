import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  margin-bottom: 60px;
  border: solid 2px #bef1e5;
  border-radius: 5px;
  padding: 45px;

  .intro-txt {
    font-size: 30px;
    line-height: 1;
    color: #ff9600;
    font-weight: 600;
  }

  .detail-txt {
    line-height: 1.8;
  }

  .btn-wrap {
    display: flex;
    width: 100%;
    justify-content: center;

    a {
      border-radius: 25vh;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
      background: #19ba9b;
      border: solid 2px #19ba9b;
      width: 200px;
      height: 50px;

      &:hover {
        opacity: 1;
        background: #ffffff;
        color: #3ec7b3;
        text-decoration: underline solid rgba(255, 255, 255, 0);
      }
    }
  }

  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：製品誘導案_キンクラを使って勤怠管理の効率化を！
 * @constructor
 */
const Introduction002 = () => (
  <Wrapper>
    <p className="intro-txt">キンクラを使って勤怠管理の効率化を！</p>
    <p className="detail-txt">
      「自社と常駐先の勤務管理に苦労していませんか？
      <br />
      「キンクラ」は勤怠管理に工数のかかる客先常駐の業務課題に対応し、効率化へ貢献するサービスです。
    </p>
    <div className="btn-wrap">
      <Link to={useRouteParam('/benefit/')} target="_blank">
        導入効果を見る
      </Link>
    </div>
  </Wrapper>
)

export default Introduction002
