import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor002 from '../../../components/molecules/columnAuthor002'
import ColumnDetailInner0005 from '../../../components/molecules/columnDetailInner0005'
import UsefulFunctionLayout003 from '../../../components/molecules/usefulFunctionLayout003'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0005 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title: '所定労働時間とは？法定労働時間との違いや残業代の計算はどうなる？',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="所定労働時間とは？法定労働時間との違いをしっかり覚えよう"
        description="勤怠管理は、労働時間に関するルールを正しく理解することが必須。労働時間を理解する際にポイントとなる「所定労働時間」を「法定労働時間」との違いを含め解説。"
        ogUrl="https://kintaicloud.jp/column/details/C0005/"
        ogType="article"
        ogTitle="所定労働時間とは？法定労働時間との違いをしっかり覚えよう"
        ogDescription="勤怠管理は、労働時間に関するルールを正しく理解することが必須。労働時間を理解する際にポイントとなる「所定労働時間」を「法定労働時間」との違いを含め解説。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0005.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0005 />
        {/** オーサ情報 */}
        <ColumnAuthor002 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout003 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0005

export const pageQuery = graphql`
  query C0005 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
