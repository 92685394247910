import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction002 from './Introduction002'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-list-link {
    position: absolute;
    top: 9px;
    right: 2px;
    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 7px;
      left: -14px;
      border-left: 8px solid #3ec7b3;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }
  }
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0005 = () => (
  <Wrapper>
    <ColumnH1 label="所定労働時間とは？法定労働時間との違いをしっかり覚えよう" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.04.15</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        昨今では、長時間労働にまつわる問題が深刻化していることを受け、国はワーク・ライフ・バランスや働き方改革などの対策法を広く推奨しています。この影響を受け、各会社に対しても社員の労働時間の正しい管理についても厳しい管理体制を敷くことが求められているのが現状です。
        <br />
        <br />
        社員の勤怠状況や労働時間の管理をするためには、
        <span>労働時間に関するルールを正しく理解する</span>
        ことが必須になります。しかし、ひとことで<span>「労働時間」</span>
        といっても、たとえば所定労働時間や法定労働時間などいくつかの種類があることから、混乱しやすいという懸念点があります。
        <br />
        <br />
        今回は、労働時間を理解する際にポイントとなる
        <span>「所定労働時間」</span>
        にスポットを当て、定義を正しく理解した上で、気になる残業代の計算法などについても伝授していきましょう。
      </p>
      <img src="/images/column/details/c0005.jpg" alt="キンクラコラム画像5" />
      <h1>そもそも「労働時間」とは何か</h1>
      <p>
        まず、すべての基本となる<span>「労働時間」</span>について説明します。
        <br />
        労働時間は、社員が雇用契約を交わした会社のために働かなければならない時間のことをいいます。労働時間は、雇われている会社の社長や上司の指揮命令下に置かれている点についても覚えておきましょう。
      </p>
      <h1>所定労働時間とは</h1>
      <p>
        所定労働時間とは、<span>会社で決められた労働時間</span>
        のことです。労働時間の詳細は、就業規則や個別に交わす労働契約書で具体的に定められています。
        <br />
        たとえば、10時から19時までの間に働き、うち13時から14時までの1時間を休憩時間として定めている会社の場合は、10時から19時までの9時間分から、休憩時間である1時間分を控除した後の8時間が所定労働時間となります。
      </p>
      <h1>法定労働時間とは</h1>
      <p>
        法定労働時間とは、労働者が守られるべき最低限の権利について定められた
        <span>「労働基準法」で定められた、上限時間</span>
        のことです。会社は、原則として社員をこの法定労働時間を超えて働かせてはなりません。
        <br />
        なお、法定労働時間の詳細は、
        <span>一日あたり8時間まで、一週間あたり40時間まで</span>が原則です。
      </p>
      <Introduction002 />
      <h1>法定労働時間と所定労働時間との違い</h1>
      <p>
        法定労働時間は一日あたり・一週間あたりに社員を働かせる労働時間の上限「ルール」であることに対して、所定労働時間は会社が独自で定めた「労働時間」であり、同じ労働時間でも内容が大きく異なります。
        <br />
        <br />
        ただし、ここで覚えておかなければならないのが、
        <span>
          法定労働時間を超えた時間を所定労働時間として定めてはならない
        </span>
        、ということです。
        <br />
        社員を雇用する会社は、当然ながら労働基準法を守らなければなりません。これはつまり、法定労働時間を守らなければならないという事でもあります。
        <br />
        したがって、会社が所定労働時間を決める際には、必ず
        <span>法定労働時間内の範囲</span>で設定をする必要があります。
        <br />
        <br />
        なお、法定労働時間を超えて社員を働かせる場合は、
        <span>別途36協定を締結し、労働基準監督署へ届け出る</span>
        必要がありますので、こちらも注意しましょう。
      </p>
      <h1>所定労働時間における残業代の計算法</h1>
      <p>
        残業代といえば、会社で定める定時を超えて仕事をした場合に発生するものだというイメージが強いと思われます。しかし、所定労働時間を超えた場合の残業と、法定労働時間を超えた場合の残業では、計算法が全く異なる点に注意が必要です。
        <br />
        <br />
        労働基準法によれば、残業代は、会社が届け出た36協定の内容や、臨時に残業の必要があると判断された場合などに、法定労働時間や法定休日を超えて社員を働かせた場合に、法で定められた割増率に沿った形で支払われなければならない、と定められています。
        <br />
        <br />
        つまり、残業代が発生する基準は
        <span>「法定労働時間・法定休日」を超えた場合</span>となります。
        <br />
        言い換えれば、所定労働時間が法定労働時間より短く設定されている場合に、法定労働時間を超えない範囲内で残業をさせたとしても、残業代を支払う義務がないことになります。
        <br />
        <br />
        たとえば、所定労働時間が6時間の会社で、社員が1時間の残業をしたとします。
        <br />
        この場合は、残業時間を加算しても一日トータルで7時間分の労働となるため、一日あたり8時間という法定労働時間のルールを超えないことから、残業代の支払義務は免れます。
        <br />
        ただし、残業代は発生しなくとも、1時間分働いたことに対する賃金自体は発生することになるため、間違えないようにしましょう。
      </p>
      <h1 className="read">まとめ</h1>
      <p>
        所定労働時間は、法律で定められた法定労働時間とは全く異なるものであることがお分かりいただけましたでしょうか。
        <br />
        労働基準法による規制がかかるのは、あくまでも法定労働時間を超えた場合であることを正しく理解し、適切な形で勤怠管理や給料を支払う必要があります。
        <br />
        <br />
        なお、
        <span>
          法定労働時間を超えない時間内の残業に対し、任意で社員に残業代を支払うことは可能
        </span>
        になることから、社員のモチベーションを高めるための一策として検討してみる方法も有効でしょう。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0005
